import React from 'react';
import Message from './Message'

class MessageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newMessage: '',
            messages: [{
                'user': 'Alice Alison',
                'message': 'Hello!'
            }, {
                user: 'Bob Boberson',
                message: 'Hi'
            }, {
                user: 'Charlie Charlston',
                message: "What's up everyone?"
            }]
        }
    }

    postMessage = () => {
        this.setState({
            messages: [...this.state.messages, {user: 'Me', message: this.state.newMessage}],
            newMessage: ''
        });
    }

    render() {
        return (
            <div className="MessageList">
                <div className="Messages">
                    {this.state.messages.map((m, i) =>
                        <div key={i}>
                            <Message user={m.user} message={m.message} />
                        </div>
                    )}
                </div>
                <div>
                    <input placeholder='Say something...' type='text'
                           value={this.state.newMessage}
                           onChange={e => this.setState({newMessage: e.target.value})}
                           onKeyDown={e => {e.key === 'Enter' && this.postMessage()}}
                    />
                    <button onClick={this.postMessage}>Post</button>
                </div>
            </div>

        );
    }
}

export default MessageList;
